import axios from 'axios'
import { BASE_URL, serverPath } from '../constants/api-endpoints'
import { store } from '../store'
import { initialAuthState, setUser, updateTokens } from '../store/auth/reducer'
import { RefreshTokenResponseDto } from '../dto/login/user.response.dto'

const axiosInstance = axios.create({
  baseURL: BASE_URL,
})

axiosInstance.interceptors.request.use((config) => {
  const { accessToken } = store.getState().auth
  config.headers.set('Authorization', `Bearer ${accessToken}`)
  return config
})

let refreshSubscribers: any[] = []

let isRefreshing = false

const subscribeTokenRefresh = (cb: any) => {
  refreshSubscribers.push(cb)
}

const onRefreshed = (token: string) => {
  refreshSubscribers.map((cb) => cb(token))
  refreshSubscribers = []
}

axiosInstance.interceptors.response.use(
  (config) => {
    return config
  },
  async (error) => {
    const originalRequest = error.config
    const { refreshToken } = store.getState().auth
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true
      if (!isRefreshing) {
        isRefreshing = true
        try {
          const { data } = await axios.get<RefreshTokenResponseDto>(
            `${BASE_URL}${serverPath.REFRESH}`,
            {
              headers: {
                ...originalRequest.headers,
                Authorization: `Bearer ${refreshToken}`,
              },
            },
          )
          store.dispatch(
            updateTokens({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
            }),
          )
          isRefreshing = false
          onRefreshed(data.accessToken)

          return axiosInstance.request(originalRequest)
        } catch (e) {
          store.dispatch(setUser(initialAuthState))
        }
      }
      return new Promise((resolve) => {
        subscribeTokenRefresh((token: string) => {
          // replace the expired token and retry
          originalRequest.headers['Authorization'] = 'Bearer ' + token
          resolve(axiosInstance.request(originalRequest))
        })
      })
    }
    throw error
  },
)

export default axiosInstance
