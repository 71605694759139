import { useEffect } from 'react'
import dayjs from 'dayjs'
import { useMessage } from './useMessage'
import { shortDateFormat } from '../utils/time'

function useReminder(targetDate: string) {
  useEffect(() => {
    const now = dayjs()
    const target = dayjs(targetDate)
    const { warn, error } = useMessage()

    const isLessThanWeek = target.diff(now, 'day') <= 7
    const isTomorrow = target.diff(now, 'day') === 1
    const isToday = target.diff(now, 'day') === 0

    const logMessage = () => {
      if (isTomorrow) {
        warn(
          `Подписка закончиться завтра. Продлите црм во избежания ее отключения. Удачной работы! `,
        )
      } else {
        warn(
          `Подписка скоро закончиться. Дата окончания: ${shortDateFormat(
            targetDate ?? '',
          )}. Продлите црм во избежания ее отключения. Удачной работы! `,
        )
      }
    }

    if (target.isBefore(now)) {
      error('Подписка закончилась')
      return
    }

    const scheduleReminder = () => {
      const currentTime = dayjs()
      if (isToday && currentTime.hour() >= 16 && currentTime.hour() <= 21) {
        logMessage()
      } else if (isLessThanWeek && currentTime.hour() === 9) {
        logMessage()
      }
    }

    const hourlyInterval = setInterval(() => {
      scheduleReminder()
    }, 60 * 60 * 1000)

    scheduleReminder()

    return () => {
      clearInterval(hourlyInterval)
    }
  }, [targetDate])
}

export default useReminder
