import React from 'react'
import { Col, Row } from 'antd'
import styles from './BannerAds.module.scss'

const BannerAds: React.FC = () => {
  return (
    <div className={styles.ads}>
      <Row justify='center'>
        <Col xl={8} lg={8} md={12} sm={24} xs={24}>
          <div className={styles.item}>
            <a href='https://t.me/carl_obnaal' target='_blank' rel='noreferrer'>
              <img src={require('../../assets/ads/item-1.gif')} alt='' />
            </a>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default BannerAds
