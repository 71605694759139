import { combineReducers, configureStore } from '@reduxjs/toolkit'

import authReducer from './auth/reducer'
import testsReducer from './tests/reducer'
import currencyReducer from './currency/reducer'
import notificationsReducer from './notifications/reducer'
import themeReducer from './theme/reducer'
import settingsReducer from './settings/reducer'
import crmInfoReducer from './crmInfo/reducer'
import linksReducer from './links/reducer'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import subscriptionsSlice from './subscriptions/reducer'
import userPermissionsReducer from './userPermissions/reducer'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [
    'auth',
    'currency',
    'themes',
    'settings',
    'crmInfo',
    'subscriptions',
    'userPermissions',
  ],
}

const rootReducer = combineReducers({
  auth: authReducer,
  currency: currencyReducer,
  notifications: notificationsReducer,
  tests: testsReducer,
  themes: themeReducer,
  settings: settingsReducer,
  crmInfo: crmInfoReducer,
  subscriptions: subscriptionsSlice,
  userPermissions: userPermissionsReducer,
  links: linksReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

//todo: fix ignoredActions
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
