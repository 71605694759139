import { AppDispatch, store } from '../index'
import axiosInstance from '../../config/axios'
import { AxiosResponse } from 'axios'
import { serverPath } from '../../constants/api-endpoints'
import { ICrmInfoRes } from '../../interfaces/crmInfo'
import { setBalace, setCrmInfo, setJivoToken } from './reducer'
import { setLoading } from '../auth/reducer'

export const getCrmInfo = () => async (dispatch: AppDispatch) => {
  try {
    const { data }: AxiosResponse<ICrmInfoRes> = await axiosInstance.get(serverPath.CRM_INFO)
    dispatch(setCrmInfo(data))
  } catch (e) {
    console.log(e)
  }
}

export const updateJivoToken = (jivoToken: string | null) => async (dispatch: AppDispatch) => {
  const {
    crmInfo: { id },
  } = store.getState()
  try {
    dispatch(setLoading(true))
    await axiosInstance.patch(serverPath.CRM_INFO_INFO + '/' + id, { jivoToken })
    dispatch(setJivoToken(jivoToken))
    return true
  } catch (e) {
    console.log(e)
  } finally {
    dispatch(setLoading(false))
  }
}

export const getBalance = () => async (dispatch: AppDispatch) => {
  try {
    const { data }: AxiosResponse<{ balance: number }> = await axiosInstance.get(serverPath.BALANCE)
    dispatch(setBalace(data.balance))
  } catch (e) {
    console.log(e)
  }
}
