import React, { useCallback, useEffect, useState } from 'react'
import { ITariff, ITransaction } from '../payment.interface'
import styles from '../PayModal.module.scss'
import { Form, Input } from 'antd'
import ButtonUi from '../../../ui/ButtonUi/ButtonUi'
import axiosInstance from '../../../../config/axios'
import { serverPath } from '../../../../constants/api-endpoints'
import { errorMapper } from '../../../../utils/errorMapper'
import { ErrorMessages } from '../../../../constants/errors'
import { useMessage } from '../../../../hooks/useMessage'
import WithLabel from '../../../ui/WithLabel/WithLabel'
import IconDollar from '../../../../utils/icons/elements/IconDollar'

interface Props {
  setTransaction: React.Dispatch<React.SetStateAction<ITransaction | undefined>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const AddBalanceTrx: React.FC<Props> = ({ setTransaction, setLoading }) => {
  const { success, error } = useMessage()
  const [form] = Form.useForm()
  const [tariffs, setTariffs] = useState<ITariff>()

  useEffect(() => {
    axiosInstance
      .get<ITariff>(serverPath.TARIFFS)
      .then(({ data }) => setTariffs(data))
      .catch((e) => {
        error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
      })
  }, [])

  const submit = useCallback(async (e: { amount: number }) => {
    setLoading(true)
    axiosInstance
      .post<ITransaction>(serverPath.BALANCE_BILLING, { amount: Number(e.amount) })
      .then(({ data }) => {
        setTransaction(data)
        success('Заявка добавлена')
      })
      .catch((e) => {
        error(errorMapper(ErrorMessages.GET_DATA, e), ErrorMessages.SERVER_ERROR)
      })
      .finally(() => setLoading(false))
  }, [])
  const amount = Form.useWatch('amount', form)

  return (
    <Form name='add_transaction' onFinish={submit} className={styles.addWrapper} form={form}>
      <div className={styles.header}>Создать заявку на пополнение баланса:</div>
      <Form.Item
        className={styles.input}
        name={'amount'}
        rules={[{ required: true, message: 'введите сумму' }]}
      >
        <WithLabel
          label={'Введите сумму USDT (TRC20):'}
          icon={<IconDollar width='18' height='18' fill='var(--color-main)' />}
        >
          <Input placeholder={`введите сумму`} type='number' min={10} />
        </WithLabel>
      </Form.Item>

      <div className={styles.subTitle}>
        {!tariffs ||
          (!tariffs['eye_of_god'] && !tariffs['cybersec'] && (
            <p className={styles.noTariff}>нет доступных тарифов!!!</p>
          ))}
        {tariffs && tariffs['eye_of_god'] && (
          <p>
            <span>{`Текущий тариф "Глаз бога" - ${tariffs['eye_of_god'] / 100}$/проверка`}</span>
            <span>
              {amount
                ? ` (примерно ${(+amount / (tariffs['eye_of_god'] / 100)).toFixed(1)} проверок)`
                : ''}
            </span>
          </p>
        )}
        {tariffs && tariffs['cybersec'] && (
          <p>
            <span>{`Текущий тариф "CyberSec" - ${tariffs['cybersec'] / 100}$/проверка`}</span>
            <span>
              {amount
                ? ` (примерно ${(+amount / (tariffs['cybersec'] / 100)).toFixed(1)} проверок)`
                : ''}
            </span>
          </p>
        )}
      </div>

      <ButtonUi htmlType='submit' color='green'>
        создать заявку
      </ButtonUi>
    </Form>
  )
}

export default AddBalanceTrx
