import React, { ReactNode, useCallback, useState } from 'react'
import classes from './RouterComponent.module.scss'
import { IRoute } from '../interfaces/router/route.interface'
import PrivateRoute from './PrivateRoute'
import { appHistory } from './history'
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { publicRoutes } from './publicRoutes'
import { privateRoutes, salaryAdminRouth } from './privateRoutes'
import { routersPath } from './routes-path'
import DashboardLayout from '../components/layout/DashboardLayout/dashboard'
import { ModalContext } from '../context/modalContext'
import { Modal } from 'antd'
const DisablePage = React.lazy(() => import('../pages/DisablePage/DisablePage'))
import NotFound from '../pages/NotFound/NotFound'
import SalaryLayout from '../components/layout/SalaryLayout/salary'
import { LoadSpinContext } from '../context/loadSpinContext'
import PageSpinner from '../components/ui/PageSpinner/PageSpinner'

export interface IModal {
  component: ReactNode | null
  closable?: boolean
  maskClosable?: boolean
  width?: string
}

export const RouterComponent: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [state, setState] = useState<IModal>({
    component: null,
    closable: true,
    maskClosable: false,
    width: undefined,
  })

  const setModalState = useCallback((data: IModal) => {
    setState({
      component: data.component,
      closable: data.closable ?? true,
      maskClosable: data.maskClosable ?? false,
      width: data?.width ?? undefined,
    })
  }, [])

  return (
    <div className={classes.main}>
      <LoadSpinContext.Provider value={{ setLoad: setIsLoading, isLoad: isLoading }}>
        <HistoryRouter history={appHistory}>
          <ModalContext.Provider value={{ setModalComponent: setModalState }}>
            <Routes>
              {publicRoutes.map((route: IRoute) => (
                <Route key={route.path} path={route.path} element={route.component} />
              ))}
              <Route path='/' element={<DashboardLayout />}>
                {privateRoutes()
                  .filter((el) => el.show)
                  .map((route: IRoute) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<PrivateRoute roles={route.roles}>{route.component}</PrivateRoute>}
                    />
                  ))}
                <Route path='/salary' element={<SalaryLayout />}>
                  {salaryAdminRouth()
                    .filter((el) => el.show)
                    .map((route: IRoute) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={<PrivateRoute roles={route.roles}>{route.component}</PrivateRoute>}
                      />
                    ))}
                </Route>
              </Route>
              <Route path={routersPath.NOT_FOUND} element={<NotFound />} />
              <Route path={routersPath.DISABLE_PAGE} element={<DisablePage />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
            {state.component && (
              <Modal
                maskClosable={state.maskClosable}
                open={Boolean(state.component)}
                footer={null}
                getContainer={() => document.getElementById('App') || document.body}
                onCancel={() => setState({ component: null, closable: true })}
                closable={state.closable}
                width={state.width}
              >
                {state.component}
              </Modal>
            )}
            {isLoading && <PageSpinner className={classes.loadSpin} />}
          </ModalContext.Provider>
        </HistoryRouter>
      </LoadSpinContext.Provider>
    </div>
  )
}
