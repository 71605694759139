import React from 'react'
import cn from 'classnames'
import cls from './TabsUi.module.scss'
import { Tabs, TabsProps } from 'antd'

interface TabsUiProps extends TabsProps {
  className?: string
  uiType?: 'default' | 'malyaStyle'
}

export const TabsUi: React.FC<TabsUiProps> = ({ className, uiType = 'malyaStyle', ...props }) => {
  return <Tabs className={cn(cls.TabsUi, className, cls[uiType])} {...props} />
}
