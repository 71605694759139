import { SubscriptionType } from '../enums/subscription'

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  FINSHED = 'FINISHED',
}

export interface ISubscription {
  id: string
  crmId: string
  startDate: string | null
  endDate: string | null
  status: SubscriptionStatus
  planId: string | null
  plan: {
    id: string
    type: SubscriptionType
    price: number | null
  } | null
}

export interface ISubscriptionReq {
  active: ISubscription | null
  pending: ISubscription | null
  billingDate: string | null
}
