import { createSlice, Draft } from '@reduxjs/toolkit'

interface IUserPermissionsState {
  permissions: string[]
  countryPermissions: string[]
}

const initialState: IUserPermissionsState = {
  permissions: [],
  countryPermissions: [],
}

export const userPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {
    setUserPermissions: (state: Draft<IUserPermissionsState>, { payload }) => {
      state.permissions = payload.permissions.map((el: { id: 'string'; name: 'string' }) => el.id)
      state.countryPermissions = payload?.countryPermissions.map(
        (el: { id: 'string'; name: 'string' }) => el.id,
      )
    },

    clearUserPermissions: (state: Draft<IUserPermissionsState>) => {
      state = initialState
    },
  },
})

export const { setUserPermissions, clearUserPermissions } = userPermissionsSlice.actions

export default userPermissionsSlice.reducer
