import { useAppSelector } from '../hooks'

export const useUserSelector = () => useAppSelector((state) => state.auth.user)
// export const useIsActiveCrmSelector = () => useAppSelector((state) => state.auth.isActive)
export const useUserRole = () => useAppSelector((state) => state.auth?.user?.role)
export const useCrmSelector = () => useAppSelector((state) => state.crmInfo)
export const useSubscriptionSelector = () => useAppSelector((state) => state.subscriptions)
export const useUserPermissionsSelector = () =>
  useAppSelector((state) => state.userPermissions.permissions)
export const useUserCountryPermissionsSelector = () =>
  useAppSelector((state) => state.userPermissions.countryPermissions)
