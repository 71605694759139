import React, { Dispatch, useCallback, useEffect, useState } from 'react'
import { useUserSelector } from '../store/selectors/auth'
import io, { Socket } from 'socket.io-client'
import { BASE_SOCKET_URL } from '../constants/api-endpoints'
import { SocketEvents } from '../enums/socket'
import { IRecallDto } from '../dto/recall/recall.dto'
import { useMessage } from './useMessage'
import { ITransmit } from '../dto/transmit'
import { formatData } from '../utils/time'
import { useAppDispatch } from '../store/hooks'
import { authLogout } from '../store/auth/thunk'
import { getFirstNotifications } from '../store/notifications/thunks'
import { getCrmInfo } from '../store/crmInfo/thunk'
import { getSubscription } from '../store/subscriptions/thunk'

export interface IUseSocket {
  socket: Socket | null
  emit(event: string, data: any): void
  subscribeToRecall(cb: Dispatch<any>): void
  unSubscribeToRecall(cb: Dispatch<any>): void
  usersStatus: string[]
  updateRecall: number
  updateTransmit: number
}

const useSocket = () => {
  const { success } = useMessage()
  const dispatch = useAppDispatch()
  const user = useUserSelector()
  const [socket, setSocket] = useState<Socket | null>(null)
  const [usersStatus, setUsersStatus] = useState<string[]>([])
  const [recallCb, setRecallCb] = useState<React.Dispatch<any>[]>([])
  const [updateRecall, setUpdateRecall] = useState(0)
  const [updateTransmit, setUpdateTransmit] = useState(0)
  const [updateCb, setUpdateCb] = useState<{ [key: string]: React.Dispatch<any>[] }>({})

  useEffect(() => {
    if (user?.id) {
      const initialSocket = io(BASE_SOCKET_URL ?? '', {
        query: { userId: user.id },
        transports: ['websocket'],
      })
      initialSocket.on(SocketEvents.USERS_STATUS, (msg) => {
        setUsersStatus(msg)
      })
      setSocket(initialSocket)
      return () => {
        initialSocket.disconnect()
      }
    }
  }, [user])

  useEffect(() => {
    if (socket) {
      socket.on(SocketEvents.RECALL, (msg: IRecallDto) => {
        recallCb.forEach((cb) => cb(msg))
      })
      socket.on(SocketEvents.UPDATE_RECALL, () => {
        setUpdateRecall((prev) => prev + 1)
      })
      socket.on(SocketEvents.CREATE_TRANSMIT, (e: ITransmit) => {
        success(
          `Передали ${e.client?.fullName} в ${formatData(e.createdAt)}`,
          `${e.user?.nickName} передал`,
        )
        setUpdateTransmit((prev) => prev + 1)
      })
      socket.on(SocketEvents.LOGOUT, () => {
        dispatch(authLogout())
      })
      socket.on(SocketEvents.NOTIFICATION, () => {
        dispatch(getFirstNotifications()).then()
      })
      socket.on(SocketEvents.UPDATE_SETTINGS, () => {
        dispatch(getCrmInfo()).then()
      })
      socket.on(SocketEvents.SUBSCRIPTION, () => {
        dispatch(getSubscription()).then()
      })
      socket.onAny((event, msg) => {
        setUpdateCb((prev) => {
          prev[event] && prev[event].forEach((cb) => cb(msg))
          return prev
        })
      })

      return () => {
        socket.off(SocketEvents.RECALL)
        socket.off(SocketEvents.UPDATE_RECALL)
        socket.off(SocketEvents.CREATE_TRANSMIT)
        socket.off(SocketEvents.LOGOUT)
        socket.off(SocketEvents.NOTIFICATION)
        socket.off(SocketEvents.UPDATE_SETTINGS)
        socket.off(SocketEvents.SUBSCRIPTION)
      }
    }
  }, [socket, recallCb])

  const subscribeToRecall = useCallback(
    (cb: React.Dispatch<IRecallDto>) => {
      setRecallCb([...recallCb, cb])
    },
    [recallCb],
  )

  const unSubscribeToRecall = useCallback(
    (cb: React.Dispatch<any>) => {
      const subscriptions = recallCb.filter((callback) => callback !== cb)
      setRecallCb(subscriptions)
    },
    [recallCb],
  )

  const subscribeToEvent = useCallback(
    (event: SocketEvents, cb: React.Dispatch<IRecallDto>) => {
      setUpdateCb((prev) => ({ ...prev, [event]: [...(prev[event] ? prev[event] : []), cb] }))
    },
    [setUpdateCb],
  )

  const unSubscribeToEvent = useCallback(
    (event: SocketEvents, cb: React.Dispatch<IRecallDto>) => {
      const subscriptions = updateCb[event]?.filter((callback) => callback !== cb)
      setUpdateCb({ ...updateCb, [event]: subscriptions })
    },
    [updateCb],
  )

  const emit = useCallback(
    (event: string, data: any) => {
      socket && socket.emit(event, data)
    },
    [socket],
  )

  return {
    socket,
    emit,
    subscribeToRecall,
    unSubscribeToRecall,
    usersStatus,
    updateRecall,
    updateTransmit,
    subscribeToEvent,
    unSubscribeToEvent,
  }
}

export default useSocket
