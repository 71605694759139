import React, { useEffect } from 'react'
import cls from './BalanceBtn.module.scss'
import ButtonUi from '../ButtonUi/ButtonUi'
import { useBalance } from '../../../store/selectors/crmInfo'
import BalanceModal from '../../Modals/PayModal/BalanceModal'
import { useModalContext } from '../../../context/modalContext'
import { useSocketContext } from '../../../context/socketContext'
import { SocketEvents } from '../../../enums/socket'
import { getBalance } from '../../../store/crmInfo/thunk'
import { useAppDispatch } from '../../../store/hooks'
import IconDollar from '../../../utils/icons/elements/IconDollar'

export const BalanceBtn: React.FC = () => {
  const balance = useBalance()
  const dispatch = useAppDispatch()
  const { subscribeToEvent, unSubscribeToEvent } = useSocketContext()

  useEffect(() => {
    const updateBalance = () => {
      dispatch(getBalance()).then()
    }
    subscribeToEvent(SocketEvents.UPDATE_CRM_BALANCE, updateBalance)
    return () => unSubscribeToEvent(SocketEvents.UPDATE_CRM_BALANCE, updateBalance)
  }, [])

  const { setModalComponent } = useModalContext()
  const handler = () => {
    setModalComponent({
      component: <BalanceModal />,
      maskClosable: true,
    })
  }
  return (
    <div className={cls.BalanceBtn} onClick={handler}>
      <IconDollar width='14' height='14' fill='var(--color-main)' />
      Пополнить пробив({balance ?? 0}$)
    </div>
  )
}
