import { Menu } from 'antd'
import {
  LogoutOutlined,
  PoweroffOutlined,
  SnippetsOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { routersPath } from '../../../../router/routes-path'
import { NavLink } from 'react-router-dom'
import React from 'react'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { useCrmPermission } from '../../../../store/selectors/crmInfo'
import { MenuDividerType } from 'antd/es/menu/interface'

export const OperatorProfile = (handler: MenuClickEventHandler) => {
  const permissions = useCrmPermission()

  return {
    items: [
      {
        disabled: false,
        key: 'profile',
        label: <span>Профиль</span>,
        icon: <UserOutlined />,
        onClick: handler,
      },
      {
        disabled: false,
        key: routersPath.MANAGERS_PAGE,
        label: (
          <NavLink to={routersPath.MANAGERS_PAGE}>
            <span>Менеджеры</span>
          </NavLink>
        ),
        icon: <TeamOutlined />,
      },
      {
        disabled: !permissions.scripts,
        key: routersPath.SCRIPTS_PAGE,
        label: (
          <NavLink to={routersPath.SCRIPTS_PAGE}>
            <span>Скрипты</span>
          </NavLink>
        ),
        icon: <SnippetsOutlined />,
      },
      {
        disabled: false,
        key: 'powerOff',
        label: <span>Выключить CRM</span>,
        icon: <PoweroffOutlined />,
        onClick: handler,
      },
      {
        type: 'divider',
        key: 'divider',
        disabled: false,
      } as MenuDividerType & { disabled: boolean },
      {
        key: 'exit',
        label: <span>Выход</span>,
        icon: <LogoutOutlined />,
        onClick: handler,
      },
    ].filter((el) => !el.disabled),
  }
}
