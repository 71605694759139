export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_DEV_URL + '/api'
    : process.env.REACT_APP_API_PROD_URL + '/api'
export const BASE_SOCKET_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_DEV_URL
    : process.env.REACT_APP_API_PROD_URL

// export const BASE_URL = 'https://crm-backend-client.herokuapp.com/api'
// export const BASE_SOCKET_URL = 'https://crm-backend-client.herokuapp.com'
// https://crm-backend-client.herokuapp.com

// export const BASE_URL = 'http://31.172.70.163/api'
// export const BASE_SOCKET_URL = 'http://31.172.70.163'

export enum serverPath {
  LOGIN = '/auth/login',
  REFRESH = '/auth/refresh',
  BASES = '/bases',
  BASES_DONE = '/bases/done',
  BASES_COUNT = '/bases/active/count',
  BASES_CLEAR = '/bases/clear',
  BASES_DOUBLE_REMOVE = '/bases/remove/duplicates',
  BASE_EXCEL = '/bases/excel',
  BASES_LIGHT = '/bases/light',
  BASES_ANALYTICS = '/bases/analytics',
  BASES_CHECK = '/bases/checked',
  BASES_CHECK_REPEAT = '/bases/repeat-checked',
  BASES_CHECK_PHONES = '/bases/check-phones',
  USER_CREATE = '/auth/create',
  USER_BULK_CREATE = '/auth/bulk-create',
  USERS = '/users',
  USERS_LIGHT = '/users/light',
  DOUBLES_GET = '/bases/duplicate',
  DOUBLES_GET_EXCEL = '/bases/duplicates/excel',
  //filters
  FILTERS = '/filters',
  FILTERS_NEW = '/filters/new',
  FILTERS_UNDERCALL = '/filters/undercall',
  FILTERS_REGIONS = '/filters/list/areas',
  FILTERS_TELECOMS = '/filters/list/telecom',
  FILTERS_UTC = '/filters/list/utc',
  TASKS = '/tasks',
  TASKS_BY_USER = '/tasks/user/',
  TASKS_RECALL = '/tasks/recall',
  TASKS_UPDATE = '/tasks/update/task',
  TASKS_HISTORY = '/tasks/history',
  RETRIEVED = '/tasks/retrieved',
  CLIENT_SEARCH = '/client/search',
  CLIENT = '/client',
  DAY_ANALYTIC = '/tasks/analytics/day',
  DAY_TASKS = '/tasks/work',
  RECALL = '/recall',
  RECALL_USER = '/recall/user',
  PRESET = '/preset',
  SCRIPT = '/script',
  STATISTICS = '/statistics',
  STATISTICS_MANAGERS = '/statistics/managers',
  SALARY = '/salary',
  SALARY_REPORT = '/salary/report',
  SALARY_INCOME = '/salary/income',
  SALARY_EXPENSES = '/salary/expenses',
  SALARY_TRANSFER = '/salary/transfer',
  SALARY_TOTAL = '/salary/total',
  TRANSMIT = '/transmit',
  WHITE_LIST = '/whitelist/',
  WHITE_LIST_UPDATE = '/whitelist/update',
  ONLINE_USERS = '/crm-info/online',
  NOTIFICATIONS = '/notifications',
  //tests
  QUESTIONNAIRE_CREATE = '/questionnaire/create',
  QUESTIONNAIRE = '/questionnaire',
  QUESTIONNAIRE_MANAGER = '/questionnaire/all/user',
  QUESTIONNAIRE_ANSWERS = '/questionnaire/answers',
  QUESTIONNAIRE_COMPLETE_USERS = '/questionnaire/complete/user',
  QUESTIONNAIRE_USER_ANSWER = '/questionnaire/answers/user',
  QUESTIONNAIRE_SUMMER_RESULTS = '/questionnaire/results',
  //billing
  BILLING_CHECK = '/billing/check',
  BILLING = '/billing',
  //balance
  BALANCE_PHONE = '/balance',
  BALANCE_BILLING = '/balance/billing',
  //tariffs
  TARIFFS = '/tariffs',
  //settings
  CRM_SETTINGS = '/crm-info/map-settings',
  CRM_FIELDS = '/crm-info/map-fields',
  CRM_INFO = '/crm-info/permission',
  CRM_INFO_INFO = '/crm-info/info',
  UPDATE_STATUSES = '/crm-info',
  CRM_OFF = '/crm-info/off',
  //PRESETS
  PRESET_PREFIX = '/preset/prefix-phone',
  //COUNTRIES
  COUNTRIES = '/country',
  //subscription
  PLANS = '/crm-info/plans',
  BALANCE = '/crm-info/balance',
  SUBSCRIBE = '/crm-info/subscribe',
  SUBSCRIPTIONS = '/crm-info/subscriptions',
  SUBSCRIPTIONS_IN_DEBT = '/crm-info/in-debt',
  //telephony
  TELEPHONY = '/telephony',
  TELEPHONY_CONFIRM = '/telephony/confirm',
  SMS_BALANCE = '/crm-info/red-sms/balance',
  //NEW DOCS
  TEMPLATES = '/templates',
  TEMPLATES_SHARE = '/templates/share',
  TEMPLATES_ADD = '/templates/add',
  //links
  LINKS = '/preset/links',
  //GENERATOR
  GENERATOR_CATEGORY = '/generator/category',
  GENERATOR_MEDIA = '/generator/media',
  GENERATOR_BANKS = '/generator/documents',
  GENERATOR_MEDIA_UPLOAD = '/generator/upload',
  //permissionsRules
  PERMISSIONS_TEMPLATES = '/permissions/lvl',
  PERMISSIONS_COUNTRIES = '/permissions/country/',
  PERMISSIONS_LVL = '/permissions/lvl-permission',
  PERMISSIONS_USERS = '/permissions/users',
  USER_PERMISSIONS = '/users/me',
  //Data check (eog, cybersec)
  EOG = '/phone-check/eye-of-god',
  CYBERSEC = '/phone-check/cybersec',
  //base export
  EXCEL_GENERATE = '/excel/generate',
  EXCEL_FILES = '/excel/files',
  EXCEL_DELETE = '/excel/delete',
  EXCEL_DELETE_ALL = '/excel/delete-all',
  EXCEL_PROGRESS = '/excel/progress',
  EXCEL_DOWNLOAD = '/excel/download',
  EXCEL_DOWNLOAD_ALL = '/excel/download-all',
}
