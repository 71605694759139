import React, { memo, useCallback, useState } from 'react'
import cn from 'classnames'
import { Button, Tabs } from 'antd'
import { ITabs } from './Tabs/AdminTabs'
import styles from './SideBar.module.scss'
import { EUserRoles } from '../../enums/roles'
import { useLocation, useNavigate } from 'react-router'
import useCurrentTab from '../../hooks/useCurrentTab'
import { useUserRole } from '../../store/selectors/auth'
import { IconMenuLeftSquare, IconPhoneCall, IconSearchText, IconSendRight } from '../../utils/icons'
import Search from 'antd/lib/input/Search'
import { routersPath } from '../../router/routes-path'
import { TabsUi } from '../ui/TabsUi/TabsUi'

// const { TabPane } = Tabs

interface IProps {
  tabs: ITabs[]
}

const SideBar: React.FC<IProps> = ({ tabs }) => {
  const { pathname } = useLocation()
  const role = useUserRole()
  const activeTab = useCurrentTab(pathname, tabs)
  const [showMenu, setShowMenu] = useState(false)
  const [search, setSearch] = useState('')
  const navigate = useNavigate()

  const showHandler = () => {
    setShowMenu((prev) => !prev)
  }

  const onSearch = useCallback(() => {
    if (search) {
      navigate(routersPath.BASES_CLIENT_FIND, {
        state: {
          search: search,
        },
      })
      setSearch('')
    }
  }, [search])

  return (
    <>
      <div className={cn(styles.sidebar, showMenu ? styles.active : '')}>
        <div className={styles.menu}>
          <p className={styles.subtitle}>Navigation</p>
          <TabsUi
            className={styles.tabs}
            tabPosition='left'
            defaultActiveKey='0'
            activeKey={String(activeTab)}
            items={tabs?.map((el, index) => ({
              label: el.title,
              key: String(index),
            }))}
          />
          {/* {tabs?.map((el, index) => (
              <TabPane tab={el.title} key={index} />
            ))}
          </Tabs> */}
        </div>

        {role === EUserRoles.ADMIN ? (
          <div>
            <Button href='https://t.me/CRMBRO_support' target='_black' className={styles.support}>
              Поддержка 24/7
              <IconSendRight width='20' height='20' fill='var(--color-main)' />
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={styles.mobileMenu}>
        <div className={styles.btn} onClick={showHandler}>
          <IconMenuLeftSquare width='20' height='20' fill='var(--color-main)' />
          Меню
        </div>

        <div className={styles.search}>
          <Search
            className='input-search'
            placeholder='Поиск клиента'
            allowClear
            onSearch={onSearch}
            value={search}
            enterButton={<IconSearchText width='22' height='22' fill='var(--color-main)' />}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <div className={cn(styles.bg, showMenu ? styles.activeBg : '')} onClick={showHandler}></div>
    </>
  )
}

export default memo(SideBar)
